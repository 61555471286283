/*Light theme*/

/*General container*/
._pendo-step-container{
    font-size:16px!important;
    font-family:Gilroy-Semibold!important;
    line-height:20px!important;
}
  
/*size of the container*/
._pendo-step-container-size{
    padding:0px!important;
}

/*Style of the container*/
._pendo-step-container-styles{
    overflow:visible!important;
    box-shadow:rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 14, 25, 0.14) 0px 30px 40px 0px!important;
    background-color:rgba(255, 255, 255, 1)!important;
    box-sizing:border-box!important;
    border-radius:0.375rem!important;
    padding:48px!important;
}

/*Caret*/
.pendo-tooltip-caret{
    opacity:1!important;
}

/*Title*/
._pendo-step-container h2 p{
    font-family: Gilroy-Bold!important;
    font-size: 2rem!important;
    font-stretch: normal!important;
    font-style: normal!important;
    line-height: normal!important;
    letter-spacing: -1px!important;
    color:#000!important;
}

/*Sub title*/
._pendo-step-container h3 p{
    font-family: Gilroy-Bold!important;
    font-size: 1.25rem!important;
    font-stretch: normal!important;
    font-style: normal!important;
    line-height: 25px!important;
    letter-spacing: -0.5px!important;	
    color:#000!important;
}

/*Bold*/
._pendo-step-container strong{
    font-family: Gilroy-Bold!important;
}

/*Italic*/
._pendo-step-container em{
    
}

/*Links*/
._pendo-step-container a{
    text-decoration:underline!important;
    color:#0089ff!important;
}

._pendo-step-container a:hover{
    color:#0089ff!important;
}

/*Paragraph*/
._pendo-step-container p{
    font-family:Gilroy-Medium!important;
    font-size: 0.875rem!important;
    font-stretch: normal!important;
    font-style: normal!important;
    line-height: 20px!important;
    letter-spacing: -0.5px!important;
    color:#899aaf!important;
}

/*Unordered list*/
._pendo-step-container ul{
    
}

/*List element*/
._pendo-step-container li{
    font-family:Gilroy-Medium!important;
    font-size: 0.875rem!important;
    font-stretch: normal!important;
    font-style: normal!important;
    line-height: 16px!important;
    letter-spacing: -0.5px!important;
    color:#899aaf!important;
}

/*Button box*/
._pendo-step-container .pendo-mock-flexbox-row{
    display:flex!important;
    align-items: center!important;
    justify-content: center!important;
    padding-top:18px!important;
}

._pendo-step-container .pendo-mock-flexbox-element{
    position:unset!important;
}

/*Primary button*/
._pendo-step-container ._pendo-button-primaryButton{
    font-family:Gilroy-Semibold!important;
    font-size:0.875rem!important;
    color:#FFFFFF!important;
    background-color:#0089ff!important;
    border-radius:0.375rem!important;
    padding:20px 32px!important;
    min-width:150px!important;
    min-height:56px!important;
    letter-spacing: -0.2px!important;
}

._pendo-step-container ._pendo-button-primaryButton:hover{
    background-color:#1a95ff!important;
}


/*Secondary button*/
._pendo-step-container ._pendo-button-secondaryButton{
    font-family:Gilroy-Semibold!important;
    font-size:0.875rem!important;
    color:#0089ff!important;
    border-radius:0.375rem!important;
    padding:20px 32px!important;
    min-width:150px!important;
    min-height:56px!important;
    letter-spacing: -0.4px!important;
}


/*Tertiary button*/
._pendo-step-container ._pendo-button-tertiaryButton{
    font-family:Gilroy-Semibold!important;
    font-size:0.75rem!important;
    color:rgb(108, 114, 148)!important;
    text-decoration:underline!important;
    letter-spacing: -0.2px!important;
}

._pendo-step-container ._pendo-button-tertiaryButton{
    color:#899aaf!important;
}

/*Close button*/
._pendo-close-guide{
    top:-1.5rem!important;
    right:-1.5rem!important;
    background:rgba(255, 255, 255, 0.8)!important;
    border-radius:100%!important;
    padding:16px!important;
    font-size:1rem!important;
    backdrop-filter:blur(12px)!important;
    width:48px!important;
    box-shadow:0 30px 40px 0 rgba(0, 14, 25, 0.14)!important;
}

/*Bottom banner*/
div[data-layout="bottomBannerBlank"] ._pendo-close-guide{
    right:16px!important;
    border-radius:unset!important;
    background-color:unset!important;
    top:calc(50% - 24px)!important;
    font-weight:bold!important;
}

div[data-layout="bottomBannerBlank"] ._pendo-step-container-size{
    z-index:999999!important;
}

div[data-layout="bottomBannerBlank"] ._pendo-step-container-styles{
    padding:16px!important;
    background-color:rgba(255, 255, 255, 0.8)!important;
    background: radial-gradient(circle at center, rgba(255, 255, 255, 1) 50%, transparent 100%);
}

div[data-layout="bottomBannerBlank"] #pendo-guide-container{
    display:flex!important;
    align-items:center!important;
    justify-content:center!important;
}

/*NPS banner*/
[data-layout="bannerNpsSurvey"] ._pendo-step-container-styles, [data-layout="bannerSuccessMessage"] ._pendo-step-container-styles{
    max-width:95%!important;
    margin:auto!important;
    border-width:0px!important;
}

#pendo-base, #pendo-backdrop, ._pendo-guide-backdrop_, ._pendo-step-container-size{
    z-index:999999!important;
}

/*Dark theme*/
/*Style of the container*/
.dark ._pendo-step-container-styles{
    background-color:rgb(5 9 22/.8)!important;
    color:#FFF!important;
}

.dark ._pendo-step-container h2 p{
    color:#FFF!important;
}

.dark ._pendo-step-container h3 p{
    color:#FFF!important;
}

.dark ._pendo-step-container p{
    color:#6c7294!important;
}

.dark ._pendo-step-container li{
    color:#6c7294!important;
}

.dark ._pendo-close-guide{
    background:rgba(5, 9, 22)!important;
}