/* 
 * Global
 */

.fullcalendar-wrapper .fc-daygrid-more-link.fc-more-link {
    @apply w-full mt-0 px-0 pb-0 pt-0.5 hover:bg-transparent;
}

.fullcalendar-wrapper .fc .fc-scrollgrid {
    @apply bg-white dark:bg-icoDarkMode-anthracite overflow-hidden rounded-md border-0;
}

.fullcalendar-wrapper .fc-theme-standard td,
.fullcalendar-wrapper .fc-theme-standard th {
    @apply border-icoGray-300 dark:border-icoDarkMode-beton;
}

.fullcalendar-wrapper .fc .fc-scrollgrid-section > * {
    @apply border-r-0;
}

.fullcalendar-wrapper .fc .fc-col-header-cell-cushion {
    @apply p-0 w-full;
}

.fullcalendar-wrapper .fc-liquid-hack .fc-timegrid-axis-frame-liquid {
    @apply relative;
}

.fullcalendar-wrapper .fc-timegrid-divider.fc-cell-shaded,
.fullcalendar-wrapper .fc .fc-timeGridWeek-view .fc-daygrid-day-top,
.fullcalendar-wrapper .fc .fc-timeGridDay-view .fc-daygrid-day-top {
    @apply hidden;
}

.fullcalendar-wrapper .fc-scroller {
    @apply !overflow-y-auto;
}

.fullcalendar-wrapper .fc .fc-dayGridMonth-view .fc-daygrid-day-frame {
    min-height: 7.813rem;
}

.fullcalendar-wrapper .fc .fc-daygrid-day-number {
    @apply p-0 w-full;
}

.fullcalendar-wrapper .fc .fc-dayGridMonth-view .fc-day-other {
    @apply bg-icoGray-100 dark:bg-icoDarkMode-deepBlack;
}

.fullcalendar-wrapper .fc .fc-dayGridMonth-view .fc-day-other * {
    @apply hidden;
}

.fullcalendar-wrapper .fc .fc-daygrid-day.fc-day-today,
.fullcalendar-wrapper .fc .fc-timegrid-col.fc-day-today {
    @apply bg-transparent;
}

.fullcalendar-wrapper .fc .fc-timegrid-axis-cushion,
.fullcalendar-wrapper .fc .fc-timegrid-slot-label-cushion {
    @apply p-0;
}

.fc-timegrid-col-misc {
    @apply relative;
}

.fc-media-screen .fc-timegrid-cols {
    @apply z-1;
}

.fullcalendar-wrapper .fc .fc-scroller-liquid-absolute {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    scroll-behavior: smooth;
}

.fullcalendar-wrapper .fc .fc-scroller-liquid-absolute::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.fullcalendar-wrapper .fc .fc-scroller-liquid-absolute::-webkit-scrollbar-track {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background-color: rgba(244, 247, 251, 0.9);
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.1) inset;
}

.fullcalendar-wrapper .fc .fc-scroller-liquid-absolute::-webkit-scrollbar-thumb {
    background-color: rgba(99, 113, 120, 0.5);
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.fullcalendar-wrapper .fc .fc-scroller-liquid-absolute::-webkit-scrollbar-thumb:hover {
    background-color: rgba(99, 113, 120, 0.4);
}

.fullcalendar-wrapper .fc-daygrid-dot-event:hover,
.fullcalendar-wrapper .fc-daygrid-dot-event.fc-event-mirror {
    @apply bg-transparent;
}

/*
 * Indicator
 */
.fc-timegrid-now-indicator-arrow {
    @apply hidden;
}
.fullcalendar-wrapper .fc .fc-timegrid-now-indicator-container {
    @apply overflow-visible;
}
.fullcalendar-wrapper .fc .fc-timegrid-now-indicator-line {
    @apply border-0 h-px bg-icoRed dark:bg-icoDarkMode-red;
}
.fullcalendar-wrapper .fc-timegrid-now-indicator-line::before {
    content: '';
    left: -4px;
    top: -3px;
    @apply flex w-1.5 h-1.5 bg-icoRed dark:bg-icoDarkMode-red rounded-full box-content absolute border-l border-t border-solid border-transparent;
}

/*
 * Events (post)
 */

.fullcalendar-wrapper .fc-v-event {
    @apply bg-transparent border-0;
}

.fullcalendar-wrapper .fc-direction-ltr .fc-timegrid-col-events {
    @apply m-0;
}

.fullcalendar-wrapper .fc-timegrid-event .fc-event-main {
    @apply p-0;
}

.fullcalendar-wrapper .fc-timegrid-event-harness-inset .fc-timegrid-event,
.fullcalendar-wrapper .fc-timegrid-event.fc-event-mirror,
.fullcalendar-wrapper .fc-timegrid-more-link {
    @apply shadow-none;
}

.fullcalendar-wrapper .fc-timeGridDay-view .fc-timegrid-event-harness {
    max-width: 244px;
    left: 50% !important;
    transform: translateX(-50%);
}

/* 
 * Event (SMC)
 */

.fullcalendar-wrapper .fc-h-event {
    @apply bg-transparent border-0 rounded-none;
}

.fullcalendar-wrapper .fc .fc-daygrid-day-bottom {
    @apply p-0;
}

.fullcalendar-wrapper .fc .fc-daygrid-event,
.fullcalendar-wrapper .fc .fc-daygrid-body-natural .fc-daygrid-day-events,
.fullcalendar-wrapper .fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fullcalendar-wrapper .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    @apply m-0;
}

.fullcalendar-wrapper .fc .fc-daygrid-day-frame {
    @apply pb-0.5;
}

.fullcalendar-wrapper .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    min-height: inherit;
}

.fullcalendar-wrapper .fc .fc-timegrid-axis-cushion {
    @apply py-1 max-w-none h-full;
}
