@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~react-image-crop/dist/ReactCrop.css';
@import '~draft-js/dist/Draft.css';

@import './calendar.css';
@import './google.css';
@import './datepicker.css';
@import './react-day-picker.css';
@import './emojipicker.css';
@import './maptooltip.css';
@import './pendo.css';
@import './richtexteditor.css';

@layer base {
    @font-face {
        font-family: 'Gilroy-Bold';
        font-weight: bold;
        font-display: swap;
        src: url('/fonts/gilroy/Gilroy-Bold.woff2') format('woff2');
    }

    @font-face {
        font-family: 'Gilroy-Semibold';
        font-weight: 600;
        font-display: swap;
        src: url('/fonts/gilroy/Gilroy-Semibold.woff2') format('woff2');
    }

    @font-face {
        font-family: 'Gilroy-Medium';
        font-weight: 500;
        font-display: swap;
        src: url('/fonts/gilroy/Gilroy-Medium.woff2') format('woff2');
    }

    [type='radio'],
    [type='checkbox'] {
        @apply bg-transparent;
    }

    [type='radio']:checked {
        @apply bg-icoRadioLight dark:bg-icoRadioDark;
        background-position: center;
    }

    [type='checkbox']:checked {
        @apply bg-icoCheckboxLight dark:bg-icoCheckboxDark dark:disabled:bg-icoCheckboxLight;
        background-size: 7px 7px;
    }

    button,
    [type='button'],
    [type='reset'],
    [type='submit'] {
        @apply appearance-none;
    }

    .scrollbar {
        @apply overflow-auto;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: thin;
    }

    .scrollbar::-webkit-scrollbar {
        @apply h-1 w-1;
    }

    .scrollbar::-webkit-scrollbar-track {
        @apply rounded-m bg-icoGray-100/90 dark:bg-icoDarkMode-beton;
        -webkit-border-radius: 4px;
        -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.1) inset;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        @apply rounded-m bg-icoGray-500/90 dark:bg-icoDarkMode-deepBlack;
        -webkit-border-radius: 4px;
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        @apply bg-icoGray-500;
    }

    button {
        @apply rounded focus:outline-none focus:ring-2 focus:ring-icoBlue-200 focus:ring-offset-2 dark:focus:ring-offset-icoDarkMode-deepBlack;
    }
}

@layer utilities {
    .animation-state-paused {
        animation-play-state: paused;
    }

    .animation-state-running {
        animation-play-state: running;
    }

    @keyframes track-progress-bar-decrease {
        0% {
            transform: scaleX(1);
        }
        100% {
            transform: scaleX(0);
        }
    }

    .animation-progress-bar-decrease {
        animation: track-progress-bar-decrease linear 1 forwards;
    }

    .post {
        @apply aspect-h-1.2 aspect-w-1;
    }

    .story {
        @apply aspect-h-2 aspect-w-1;
    }

    .square {
        @apply aspect-h-1 aspect-w-1;
    }

    .storyImg {
        @apply aspect-h-1.78 aspect-w-1;
    }

    .albumImgGrid {
        @apply aspect-h-0.93 aspect-w-1;
    }

    .facebook-preview-link {
        @apply aspect-h-0.52 aspect-w-1;
    }

    .linkedin-preview-media {
        @apply aspect-h-0.75 aspect-w-1;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    @keyframes shine {
        0% {
            transform: rotate(-45deg) translateX(-140%);
        }
        80% {
            transform: rotate(-45deg) translateX(100%);
        }
        100% {
            transform: rotate(-45deg) translateX(100%);
        }
    }

    .shining {
        @apply relative inline-block max-w-min overflow-hidden after:absolute after:left-0 after:top-0 after:z-2 after:h-20 after:w-full after:-rotate-45;
    }

    .shining::after {
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(128, 186, 232, 0) 99%,
            rgba(125, 185, 232, 0) 100%
        );
        transform-origin: 0 0;
        animation: shine 1.5s infinite;
    }

    @keyframes comeInOut {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }

    .comeInOut {
        animation: comeInOut 850ms forwards;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .spin {
        animation: spin 1000ms linear;
    }
}

.embla__viewport.is-draggable {
    cursor: grab;
}

.embla__viewport.is-dragging {
    cursor: grabbing;
}

/* REACT GRID LAYOUT START */
.react-grid-placeholder {
    @apply bg-icoGray-400;
}

.react-draggable-dragging {
    @apply z-10;
}

/* REACT GRID LAYOUT END */

/* Recharts style */
/* PASTE CSS HERE */
.recharts-cartesian-axis-tick-value {
    @apply fill-current font-GSemiBold text-xxs text-icoGray-500 dark:text-icoDarkMode-wolf;
}

.recharts-surface {
    @apply overflow-visible;
}

.recharts-label {
    @apply fill-current font-GMedium text-xs text-icoGray-500 dark:text-icoDarkMode-wolf;
}

@media print {
    .recharts-wrapper,
    .recharts-surface {
        width: 100% !important;
    }

    body {
        -webkit-print-color-adjust: exact;
    }
}

.Toastify__toast-container {
    @apply w-auto p-0;
}

.Toastify__toast {
    @apply font-GMedium text-sm;
}

.Toastify__toast--default {
    @apply mb-5 flex min-h-[3.5rem] items-center rounded-m px-5 shadow-icoXL;
    @apply bg-white dark:bg-icoDarkMode-deepBlack;
}

.Toastify__toast--error:before {
    content: '';
    @apply absolute inset-0 z-0 inline-block bg-icoRed-100 text-icoRed dark:bg-icoRed/20;
}

.Toastify__toast--error {
    @apply mb-5 flex min-h-[3.5rem] items-center rounded-m px-5 shadow-icoXL;
    @apply bg-white text-icoRed dark:bg-icoDarkMode-anthracite;
}

.Toastify__toast--success:before {
    content: '';
    @apply absolute inset-0 z-0 inline-block bg-icoGreen-100 text-icoGreen dark:bg-icoGreen/20;
}

.Toastify__toast--success {
    @apply mb-5 flex min-h-[3.5rem] items-center rounded-m px-5 shadow-icoXL;
    @apply bg-white text-icoGreen dark:bg-icoDarkMode-anthracite;
}

.Toastify__toast--error *,
.Toastify__toast--success * {
    @apply z-1;
}

.Toastify__toast--error i {
    @apply text-icoRed;
}

.Toastify__toast--success i {
    @apply text-icoGreen;
}

.Toastify__toast--error .Toastify__toast-icon {
    @apply hidden;
}
.Toastify__toast--success .Toastify__toast-icon {
    @apply hidden;
}

.Toastify__progress-bar.Toastify__progress-bar--error {
    background: theme('colors.icoRed.DEFAULT');
}
.Toastify__progress-bar.Toastify__progress-bar--success {
    background: theme('colors.icoGreen.DEFAULT');
}
.Toastify__progress-bar {
    background: theme('colors.icoBlue.DEFAULT');
}

.Toastify__toast-body {
    @apply m-0 p-0;
}

.Toastify__toast-container--bottom-center {
    @apply bottom-0;
}

/* New Mentions text area */

.mentions-new {
    @apply z-10 w-full break-all font-GMedium leading-5; /*add higher z-index for mentions suggestions*/
}

.mentions-new.cross-post {
    @apply h-20;
}

.mentions-new__control {
    @apply h-full;
}

.mentions-new__highlighter {
    @apply h-full border-0 !important;
    word-break: break-word !important;
}

.mentions-new__input {
    @apply scrollbar h-full overflow-auto border-0 bg-transparent p-0 font-GMedium leading-5 text-black placeholder:text-sm placeholder:text-icoGray-400 focus:outline-0 focus:ring-0 dark:text-white placeholder:dark:text-icoDarkMode-wolf !important;
    word-break: break-word !important;
}

.mentions-new__suggestions {
    @apply z-101 rounded-md bg-transparent !important;
}

.mentions-new__suggestions--disabled {
    @apply pointer-events-none z-101 rounded-md bg-transparent !important;
}
.mentions-new__suggestions__item {
    @apply line-clamp-1 overflow-hidden;
}

.mentions-new__suggestions__item__display {
    @apply line-clamp-1;
}
.mentions-new__suggestions__item__highlight {
    @apply line-clamp-1;
}

.mentions-new__suggestions__list {
    @apply scrollbar z-10 max-h-36 w-52 overflow-auto rounded-md border border-icoGray-300 bg-white p-1 dark:divide-icoDarkMode-beton dark:border-icoDarkMode-beton dark:bg-icoDarkMode-anthracite !important;
}

/* TODO: Remove  these styles when new scheduler is done */
/* Mentions in Scheduler caption */
.mentions {
    @apply z-10 h-40 w-full break-all font-GMedium text-sm leading-5; /*add higher z-index for mentions suggestions*/
}

.mentions.cross-post {
    @apply h-20;
}

.mentions__control {
    @apply h-full;
}

.mentions__highlighter {
    @apply h-full border-0 !important;
    word-break: break-word !important;
}

.mentions__input {
    @apply scrollbar overflow-auto border-0 bg-transparent p-0 font-GMedium leading-5 text-black placeholder:text-sm placeholder:text-icoGray-400 focus:outline-0 focus:ring-0 dark:text-white placeholder:dark:text-icoDarkMode-wolf !important;
    word-break: break-word !important;
}

.mentions__suggestions {
    @apply z-101 bg-transparent !important;
}

.mentions__suggestions__list {
    @apply scrollbar z-10 max-h-36 divide-y divide-icoGray-300 overflow-auto rounded-md border border-icoGray-300 bg-white dark:divide-icoDarkMode-beton dark:border-icoDarkMode-beton dark:bg-icoDarkMode-anthracite !important;
}

.mention {
    @apply relative z-1 bg-icoBlue-100 text-icoBlue !important;
}

/* Mentions in Comment forms */

.mention-picker__input {
    @apply scrollbar h-full max-h-28 w-full resize-none !overflow-auto border-0 bg-transparent p-0 px-5 !font-GMedium !text-sm !leading-5 placeholder-icoGray-500 focus:outline-none focus:ring-0 dark:placeholder-icoDarkMode-wolf;
    word-break: break-word !important;
}

.mention-picker__highlighter {
    @apply h-full max-h-28 w-full resize-none !border-0 bg-transparent p-0 px-5 !font-GMedium !text-sm !leading-5 placeholder-icoGray-500 focus:outline-none focus:ring-0 dark:placeholder-icoDarkMode-wolf;
    word-break: break-word !important;
}

.mention-picker__suggestions {
    @apply bg-transparent !important;
}

.mention-picker__suggestions__list {
    @apply scrollbar max-h-28 divide-y divide-icoGray-300 rounded-md border border-icoGray-300 bg-white text-xs text-icoGray-500 shadow-icoM focus:outline-none dark:divide-icoDarkMode-beton dark:border-icoDarkMode-beton dark:!bg-icoDarkMode-anthracite;
}

.mention-picker__suggestions__item .mention-picker__suggestions__item__highlight {
    @apply font-GBold;
}

.ui-video-seek-slider {
    @apply absolute bottom-0 left-0 z-1 h-[102px] w-full;
}

.ui-video-seek-slider:focus {
    @apply outline-0;
}

.ui-video-seek-slider .track {
    @apply h-full cursor-pointer outline-0;
}
.ui-video-seek-slider .track:focus {
    @apply border-0 outline-0;
}

.ui-video-seek-slider .track .main {
    @apply absolute inset-0;
}

.ui-video-seek-slider .track .main:focus {
    @apply border-0 outline-0;
}

.ui-video-seek-slider .track .main .buffered {
    @apply absolute inset-0 z-10 !scale-x-100;
}

.ui-video-seek-slider .track .main .seek-hover {
    @apply absolute inset-0 z-1 opacity-0;
}

.ui-video-seek-slider .track .main .connect {
    @apply absolute inset-0 z-20;
}

.ui-video-seek-slider .thumb {
    @apply pointer-events-none absolute left-0 top-0 z-30 h-full;
}
.ui-video-seek-slider .thumb .handler {
    @apply h-full w-0.5 bg-icoBlue;
}

.ui-video-seek-slider .thumb .handler:before {
    content: '';
    @apply absolute -left-0.75 top-0 inline-block h-0 w-0;
    @apply border-l-4 border-r-4 border-t-[6px] border-solid border-b-transparent border-l-transparent border-r-transparent border-t-icoBlue;
}

.ui-video-seek-slider .hover-time {
    @apply hidden;
}

.ReactCrop {
    @apply align-top;
}

.ReactCrop > div {
    @apply h-full;
}

/*
 * Tiktok player
 */

blockquote.tiktok-embed {
    margin: 0;
}

/* Sign in Slider */
.sign-in-slide:before {
    background: linear-gradient(
        160deg,
        rgba(0, 137, 255, 1) 0%,

        rgba(0, 137, 255, 1) 20%,

        rgba(0, 137, 255, 0) 50%,

        transparent 100%
    );
}

/* G2 embed forced styles*/
#g2-write-a-review-button {
    @apply !rounded-m !font-GMedium !text-xs;
}

/* React document pdf viewer */
.react-pdf__Page {
    @apply flex items-center justify-center overflow-auto !bg-icoGray-300;
    height: 400px !important;
}

.react-pdf__Page__canvas {
    @apply !h-auto !w-auto !max-w-full;
    max-height: 400px !important;
}

.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
    display: none;
}

.zapier-wrapper .hydrated {
    @apply bg-icoGray-100 p-4;
}

/* React easy crop  */

.reactEasyCrop_CropArea, .reactEasyCrop_CropArea::before, .reactEasyCrop_CropArea::after {
    border-color: #d9d9d9!important;
}

.reactEasyCrop_Contain {
    min-width: 100%;
    min-height: 100%;
    object-fit: contain;
}
